<template>
  <div>
    <PatientButton @getPatientInfo="getPatientInfo" />
    <div v-if="list.length > 0" class="appointmentList">
      <div v-for="(item, index) in list" :key="index" class="item">
        <div class="title">
          {{ item.chargeDate }}
        </div>
        <div class="list">
          <div class="grid-table">
            <div>支付流水号</div>
            <div>{{ item.serialNumber }}</div>
            <div>缴款金额</div>
            <div>{{ item.fee }}(元)</div>
            <div>支付方式</div>
            <div>{{ item.tradeType }}</div>
          </div>
        </div>
      </div>
    </div>
    <van-empty v-else description="暂无充值信息，请重新查询" />
  </div>
</template>

<script>
  import store from '@/store'
  import moment from 'moment'
  import PatientButton from '@/components/PatientButton'
  import { Dialog, Toast } from 'vant'
  import { getHospitalPreFeeList } from '@/api/his/his'
  export default {
    name: 'PreFee',
    components: { PatientButton },
    data() {
      return {
        active: '全部',
        total: null,
        list: [],
        search: {
          patientId: '',
        },
      }
    },
    async created() {
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      if (userInfo != null) {
        this.search.patientId = userInfo.patinetId
        await this.fetchData()
      } else {
        this.dialog = Dialog.alert({
          message: '暂未绑定就诊人，点击确定前往。',
          closeOnPopstate: false,
        }).then(() => {
          this.$router.push({
            path: '/message',
            query: { sign: 'gzrgl' },
          })
        })
      }
    },
    beforeDestroy() {
      Dialog.close()
    },
    methods: {
      async fetchData() {
        Toast.loading({
          duration: 0, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getHospitalPreFeeList(this.search)
        this.total = data.chargeamount
        this.list = data.feeInfo
        Toast.clear()
      },

      async getPatientInfo() {
        let userInfo = JSON.parse(store.getters['user/userInfo'])
        if (userInfo != null) {
          console.log(userInfo)
          this.search.patientId = userInfo.patinetId
          await this.fetchData()
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .van-tabs {
    width: 95%;
    margin: 0.4rem auto auto;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
  }
  .appointmentList {
    width: 95%;
    margin: 0.4rem auto auto;
    background: #fff;
    border-radius: 10px;
    font-size: 0.426rem;
    overflow: hidden;
    font-family: 'Microsoft Yahei';
    .title {
      font-weight: bolder;
      padding: 0.3rem;
      background: linear-gradient(77deg, #e0ebfd 2%, #ffffff 96%, #ffffff 96%);
      color: #1691fe;
      span {
        display: inline-block;
        float: right;
        color: #28a881;
      }
      span.gray {
        color: #acacac;
      }
    }

    .list {
      padding: 15px;
    }
  }

  .grid-table {
    display: grid;
    grid-template-columns: 1.5fr 5fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    div:nth-child(odd) {
      color: #9e9e9e;
      font-size: 0.4rem;
    }
    .dept {
      color: #646464;
      font-size: 0.4rem;
    }
  }
</style>
